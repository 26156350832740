$red: #C80A28;
$blue: #037dbe;
$grey: #f4f4f4;
$black: #000000;
$paleBlue: #DCE8EF;
$palerBlue: #ECF3F7;

html, body, .App{
	scroll-behavior: smooth;
}

.loader{
	background: linear-gradient(-45deg, $red, $blue);
	background-size: 400% 400%;
	animation: gradient 10s ease infinite;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.App{
	&:after{
		content: "";
		width: 50px;
		height: 38px;
		@apply bg-blue block fixed bottom-0 right-0;
	}

	&:before{
		content: "";
		width: 0;
		height: 0;
		@apply block fixed bottom-0 z-50;
		border-bottom: 38px solid $blue;
		border-left: 38px solid transparent;
		right: 50px;
	}

	.loader &{
		&:before,
		&:after{
			display: none;
		}
	}
}

h1,h2,h3,h4{
	@apply font-light;
}

h1{
	@apply leading-none;
}

h3{
	@apply font-semibold;
}

strong{
	@apply font-semibold;
}

ol{
	@apply mb-4;
	counter-reset: li;

	li{
		@apply relative pl-6;
		counter-increment: li;
	}

	li:before{
		@apply h-full inline-block text-blue font-bold absolute top-0 left-0;
		content: counter(li);
		width: 1.5rem;
		display: inline-block;
	}
}

ul{
	@apply list-none pl-4 mb-4;

	li{
		&:before{
			content: "\2022";
			@apply text-blue font-bold inline-block -ml-4;
			width: 1em;
		}
	}
}

em{
	font-size: 10px;
}

.loader{
	.App-header{
		display: none;
	}
	.menu-button,
	.search-button{
		display: none;
	}
}

.back-button{
	opacity: 1;

	.home &{
		opacity: 0;
	}
}

.modal{
	z-index: 9999;
	background: linear-gradient(-45deg, rgba($red,0.4), rgba($blue,0.4) 70%);

	p{
		@apply mb-4;
		font-size: inherit;
	}
}

// menu

.sidebar-menu {
	width: 300px;
	height: 100%;
	position: fixed;
	top: 0;
	left: -300px;
	z-index: 9999;
	transform: translateX(0);
	transition: .3s ease all;

	&.open {
		-webkit-transform: translateX(300px);
		transform: translateX(300px);
	}

	ul {
		> li {
			&:before{
				display: none;
			}
		}
	}
}

// main

header,
main{
	transform: translateX(0);
	transition: .3s ease all;
}

.menu-open{
	transform: translateX(300px);
}

// swiper
.swiper-button-disabled{
	opacity: 0;
}

.swiper-container{
	margin-left: 0 !important;
	margin-right: 0 !important;
	cursor: grabbing;
	padding-left: 1.5rem !important;

	@media only screen and (min-width: 768px) {
		padding-left: 2rem !important;
	}

	.relative{
		backface-visibility: hidden;

		img,
		svg{
			transition: all .5s;
		}

		&:hover{
			svg{
				font-size: 3rem;
			}

			img{
				transform: scale(1.1);
			}
		}
	}
}

nav ul > li a{
	&.active{
		@apply font-bold;
	}
}

.min-h-160{
	min-height: 160px;
}

// markdown areas
.has-markdown{
	h4, p{
		@apply mb-4;
	}
	ul{
		@apply leading-loose mb-0;
	}
}